import { withErrorHandling } from '@bd/admin/store/helpers'
import { AppState, ClientState } from '@bd/admin/types'
import { BuyerSaveDto, ClientParams, ClientRole, SellerSaveDto } from '@bd/api'
import { adminApi } from '@bd/api/admin-api'
import {
  addCustomer,
  addSeller,
  removeClient,
  updateCustomer,
  updateSeller,
} from '@bd/api/admin-api/api'
import { logErr } from '@bd/helpers'
import { ActionTree } from 'vuex'
import { getClientTypeFromRoles } from './utils'

export const actions: ActionTree<ClientState, AppState> = {
  async clientList({ state, commit }) {
    commit('SET_LOADING', true, { root: true })
    try {
      const { data } = await adminApi.clients(state.filters)
      commit('SET_CLIENTS', data)
    } catch (error) {
      logErr(error)
    }
    commit('SET_LOADING', false, { root: true })
  },
  async setFilterAndRefresh({ commit, dispatch }, payload: ClientParams) {
    commit('SET_FILTERS', payload)
    await dispatch('clientList')
  },
  addSeller: withErrorHandling(async (seller: SellerSaveDto) => {
    return addSeller(seller)
  }),
  updateSeller: withErrorHandling(async (seller: SellerSaveDto) => {
    return updateSeller(seller)
  }),
  addCustomer: withErrorHandling(async (buyer: BuyerSaveDto) => {
    return addCustomer(buyer)
  }),
  updateCustomer: withErrorHandling(async (buyer: BuyerSaveDto) => {
    return updateCustomer(buyer)
  }),
  async client({ commit, dispatch }, id: string) {
    try {
      const { data } = await adminApi.client(id)
      commit('SET_CLIENT_DETAILS', data)
      const clientType = getClientTypeFromRoles(data.roles)
      if (clientType) {
        dispatch('setSelectedClientType', clientType)
      }
    } catch (error) {
      logErr(error)
    }
  },
  async blockClient({ commit }, id: string) {
    try {
      await adminApi.blockClient(id)
      commit('TOGGLE_BLOCK_CLIENT')
    } catch (error) {
      logErr(error)
      throw error
    }
  },
  async unblockClient({ commit }, id: string) {
    try {
      await adminApi.unblockClient(id)
      commit('TOGGLE_BLOCK_CLIENT')
    } catch (error) {
      logErr(error)
      throw error
    }
  },
  async removeClient(_, id: string) {
    try {
      await removeClient(id)
    } catch (error) {
      logErr(error)
      throw error
    }
  },
  setSelectedClientType({ commit }, payload: ClientRole) {
    commit('SET_SELECTED_CLIENT_TYPE', payload)
  },
}
