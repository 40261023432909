import { ActionTree } from 'vuex'
import { AgentState, AppState } from '@bd/admin/types'
import { logErr } from '@bd/helpers'
import { adminApi } from '@bd/api/admin-api'

export const actions: ActionTree<AgentState, AppState> = {
  async agentList({ commit }) {
    try {
      const { data } = await adminApi.agents()
      commit('SET_AGENTS', data)
    } catch (error) {
      logErr(error)
    }
  },
}
