import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { isLoggedGuard, isNotLoggedGuard } from '@itcraft-bestdeal/components'
import store from '@bd/admin/store'
import AppLayout from '@bd/admin/components/AppLayout.vue'
import adminRouteGuard from './guards/admin-guard'

const calendarEventRoutes: Array<RouteRecordRaw> = [
  {
    path: 'event',
    name: 'CalendarEvent',
    redirect: '/calendar',
    component: () =>
      import(
        /* webpackChunkName: "CalendarEvent" */ '../views/Calendar/CalendarEvent/CalendarEventRouterView.vue'
      ),
    children: [
      {
        path: ':id',
        name: 'CalendarEventDetails',
        component: () =>
          import(
            /* webpackChunkName: "CalendarEventDetails" */ '../views/Calendar/CalendarEvent/CalendarEventDetails.vue'
          ),
      },
      {
        path: 'add',
        name: 'CalendarEventNew',
        component: () =>
          import(
            /* webpackChunkName: "CalendarEventSave" */ '../views/Calendar/CalendarEvent/CalendarEventSave.vue'
          ),
      },
      {
        path: ':id/edit',
        name: 'CalendarEventEdit',
        component: () =>
          import(
            /* webpackChunkName: "CalendarEventSave" */ '../views/Calendar/CalendarEvent/CalendarEventSave.vue'
          ),
      },
    ],
  },
]

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: AppLayout,
    redirect: './calendar',
    beforeEnter: isLoggedGuard(store),
    children: [
      {
        path: '/calendar',
        component: () =>
          import(
            /* webpackChunkName: "Calendar" */ '../views/Calendar/CalendarRouterView.vue'
          ),
        children: [
          {
            path: '',
            name: 'Calendar',
            component: () =>
              import(
                /* webpackChunkName: "Calendar" */ '../views/Calendar/Calendar.vue'
              ),
          },
          ...calendarEventRoutes,
        ],
      },
      {
        path: '/offers',
        name: 'Offers',
        component: () =>
          import(/* webpackChunkName: "Offers" */ '../views/Offers/Offers.vue'),
      },
      {
        path: '/offers/add',
        name: 'OfferSave',
        component: () =>
          import(
            /* webpackChunkName: "OfferSave" */ '../views/Offers/OfferSave.vue'
          ),
      },
      {
        path: '/offers/:id',
        name: 'OfferUpdate',
        beforeEnter: async (to, _, next) => {
          await store.dispatch('offers/getOfferDetails', to.params.id)
          next()
        },
        component: () =>
          import(
            /* webpackChunkName: "OfferUpdate" */ '../views/Offers/OfferSave.vue'
          ),
      },
      {
        path: '/clients',
        name: 'Clients',
        component: () =>
          import(
            /* webpackChunkName: "Clients" */ '../views/Clients/Clients.vue'
          ),
      },
      {
        path: '/clients/add',
        name: 'ClientSave',
        component: () =>
          import(
            /* webpackChunkName: "ClientSave" */ '../views/Clients/ClientSave.vue'
          ),
      },
      {
        path: '/clients/:id',
        name: 'ClientDetails',
        beforeEnter: async (to, _from, next) => {
          await store.dispatch('clients/client', to.params.id)
          next()
        },
        component: () =>
          import(
            /* webpackChunkName: "ClientDetails" */ '../views/Clients/Client.vue'
          ),
      },
      {
        path: '/clients/:id/update',
        name: 'ClientUpdate',
        component: () =>
          import(
            /* webpackChunkName: "ClientUpdate" */ '../views/Clients/ClientSave.vue'
          ),
      },
      {
        path: '/employees',
        name: 'Employees',
        beforeEnter: adminRouteGuard(store),
        component: () =>
          import(
            /* webpackChunkName: "Employees" */ '../views/Employees/Employees.vue'
          ),
      },
      {
        path: '/employees/add',
        name: 'EmployeeSave',
        component: () =>
          import(
            /* webpackChunkName: "EmployeeSave" */ '../views/Employees/EmployeeSave.vue'
          ),
      },
      {
        path: '/employees/:id',
        name: 'EmployeeDetails',
        async beforeEnter(to) {
          await store.dispatch('employees/employee', to.params.id)
        },
        component: () =>
          import(
            /* webpackChunkName: "EmployeeDetails" */ '../views/Employees/Employee.vue'
          ),
      },
      {
        path: '/employees/:id/update',
        name: 'EmployeeUpdate',
        async beforeEnter(to) {
          await store.dispatch('employees/employee', to.params.id)
        },
        component: () =>
          import(
            /* webpackChunkName: "EmployeeUpdate" */ '../views/Employees/EmployeeSave.vue'
          ),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter: isNotLoggedGuard(store),
    component: () =>
      import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    beforeEnter: isNotLoggedGuard(store),
    component: () =>
      import(
        /* webpackChunkName: "PasswordReset" */ '../views/PasswordReset.vue'
      ),
  },
  {
    path: '/password-reset-confirmation',
    name: 'PasswordResetConfirmation',
    beforeEnter: isNotLoggedGuard(store),
    component: () =>
      import(
        /* webpackChunkName: "PasswordResetConfirmation" */ '../views/PasswordResetConfirmation.vue'
      ),
  },
  {
    path: '/:catchAll(.*)*',
    name: 'NotFound',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_ROOT_URL),
  routes,
})

export default router
