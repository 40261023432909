import {
  ApiResponse,
  ClientDto,
  ResultListDto,
  ResultPageDto,
  SimpleResultListDto,
} from '@bd/api'
import {
  AddOfferStatisticsDto,
  AdminAgentRowDto,
  AdminOfferRowDto,
  AdminOffersParams,
  AvatarDto,
  AddEmployeeDto,
  EditEmployeeDto,
  EmployeeParams,
  EmployeeQueryParams,
  EmployeeDetailsDto,
  EditOfferDto,
  OfferResponse,
  UserQueryParams,
  PhotoDto,
  CityAddDtoWithProvince,
} from '@bd/api/admin-api/types'
import {
  BuyerSaveDto,
  CityDto,
  ClientDetailsDto,
  ClientParams,
  DistrictDto,
  http,
  ResultSliceDto,
  SellerSaveDto,
  SimpleResponse,
} from '@bd/api/common'
import { OfferDetailsDto } from '@bd/store-modules/types'
import { stringify } from 'qs'

export const offers = (params: AdminOffersParams) => {
  return http.get<ResultPageDto<AdminOfferRowDto>>('/offers', {
    params,
    paramsSerializer: (params) => {
      return stringify(params, { arrayFormat: 'repeat' })
    },
  })
}

export const getOfferDetails = (id: OfferDetailsDto['id']) => {
  return http.get<OfferDetailsDto>(`/offer/${id}`)
}

export const addOffer = (offer: EditOfferDto) => {
  return http.post<OfferResponse>('/offer/add', offer)
}

export const updateOffer = (offer: EditOfferDto) => {
  return http.put<OfferResponse>(`/offer/edit/${offer.id}`, offer)
}

export const removeOffer = (id: OfferDetailsDto['id']) => {
  return http.delete<void>(`/offer/${id}`)
}

export const publishOffer = (id: OfferDetailsDto['id']) => {
  return http.post<void>(`/offer/${id}/publish`)
}

export const unpublishOffer = (id: OfferDetailsDto['id']) => {
  return http.post<void>(`/offer/${id}/unpublish`)
}

export const agents = (): ApiResponse<
  SimpleResultListDto<AdminAgentRowDto>
> => {
  return http.get('/offers/agents')
}

export const uploadImage = (data: FormData) => {
  return http.post<PhotoDto>('/offer/upload-photo', data)
}

export const employees = (
  params: EmployeeParams,
): ApiResponse<ResultListDto<EmployeeDetailsDto>> => {
  return http.get('/employee', {
    params,
    paramsSerializer: (params) => {
      return stringify(params, { arrayFormat: 'repeat' })
    },
  })
}

export const uploadAvatar = (data: FormData): ApiResponse<AvatarDto> => {
  return http.post('/employee/upload-avatar', data)
}

export const updateAvatar = (payload: {
  avatar: FormData
  userId: EmployeeDetailsDto['userId']
}): ApiResponse<AvatarDto> => {
  return http.post<AvatarDto>(
    `/employee/upload-avatar/${payload.userId}`,
    payload.avatar,
  )
}

export const getAvatarImage = (avatarUrl: string) => {
  return http.get<Blob>(avatarUrl, { responseType: 'blob' })
}

export const addEmployee = (employee: AddEmployeeDto) => {
  return http.post<EmployeeDetailsDto['userId']>('/employee/add', employee)
}

export const updateEmployee = (employee: EditEmployeeDto) => {
  return http.put<EmployeeDetailsDto['userId']>('/employee/edit', employee)
}

export const employee = (id: EmployeeDetailsDto['userId']) => {
  return http.get<EmployeeDetailsDto>(`/employee/${id}`)
}

export const removeEmployee = (id: EmployeeDetailsDto['userId']) => {
  return http.delete<void>(`/employee/${id}`)
}

export const blockEmployee = (id: EmployeeDetailsDto['userId']) => {
  return http.post<void>(`/employee/block/${id}`)
}

export const unblockEmployee = (id: EmployeeDetailsDto['userId']) => {
  return http.post<void>(`/employee/unblock/${id}`)
}

export const clients = (
  params: ClientParams,
): ApiResponse<ResultSliceDto<ClientDto>> => {
  return http.get('/client', {
    params,
    paramsSerializer: (params) => {
      return stringify(params, { arrayFormat: 'repeat' })
    },
  })
}

export const addSeller = (
  seller: SellerSaveDto,
): ApiResponse<SimpleResponse<ClientDetailsDto>> => {
  return http.post('/client/seller', seller)
}

export const addCustomer = (
  customer: BuyerSaveDto,
): ApiResponse<SimpleResponse<ClientDetailsDto>> => {
  return http.post('/client/customer', customer)
}

export const updateSeller = (
  seller: SellerSaveDto,
): ApiResponse<SimpleResponse<ClientDetailsDto>> => {
  return http.put('/client/seller', seller)
}

export const updateCustomer = (
  customer: BuyerSaveDto,
): ApiResponse<SimpleResponse<ClientDetailsDto>> => {
  return http.put('/client/customer', customer)
}

export const client = async (userId: string) => {
  const res = await http.get<ClientDetailsDto>(`/client/${userId}`)
  res.data.notes.sort((a, b) => b.createdAt - a.createdAt)
  return res
}

export const blockClient = (id: string): ApiResponse<void> => {
  return http.post(`/client/block/${id}`)
}

export const unblockClient = (id: string): ApiResponse<void> => {
  return http.post(`/client/unblock/${id}`)
}

export const exportToAsari = (offerId: number): ApiResponse<void> => {
  return http.post(`/upload-offer`, { value: offerId })
}

export const addStatistics = (
  statistics: AddOfferStatisticsDto,
): ApiResponse<void> => {
  return http.post('/offer-statistics', statistics)
}

export const employeeByQuery = (
  params: EmployeeQueryParams,
): ApiResponse<ResultListDto<ClientDto>> => {
  return http.get('/employee-by-query', { params })
}

export const searchClient = (
  params: UserQueryParams,
): ApiResponse<ResultPageDto<ClientDto>> => {
  return http.get('/client-by-query', {
    params: {
      ...params,
    },
  })
}

export const removeClient = (id: string): ApiResponse<void> => {
  return http.delete(`/client/${id}`)
}

export const cities = (query: string) => {
  return http.get<SimpleResultListDto<CityDto>>('/city', {
    params: { name: query },
  })
}

export const districtsForCity = (id: CityDto['cityId']) => {
  return http.get<DistrictDto[]>(`/city/${id}/districts`)
}

export const addCity = (payload: CityAddDtoWithProvince) => {
  return http.post<CityDto>(`/province/${payload.provinceId}/city`, {
    cityName: payload.cityName,
    districtNames: payload.districtNames,
  })
}
