import { addOrRemoveClientsFilter } from '@bd/admin/services/mutation.service'
import { ClientResults, ClientState } from '@bd/admin/types'
import {
  AdminClientSaveDto,
  ClientDetailsDto,
  ClientDto,
  ClientParams,
  ClientRole,
} from '@bd/api'
import { uniqBy } from '@bd/helpers'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<ClientState> = {
  SET_CLIENTS(state, payload: ClientResults) {
    if (!state.filters.pageIndex) {
      state.clients = payload
    } else {
      state.clients = {
        ...payload,
        content: uniqBy(
          [...state.clients.content, ...payload.content],
          (client) => client.id,
        ),
      }
    }
  },
  SET_FILTERS(state, payload: ClientParams) {
    const filters = { ...state.filters }
    const result = addOrRemoveClientsFilter(filters, payload)
    if (result) {
      state.filters = result
    }
  },
  SET_CLIENT_DETAILS(state, payload: ClientDetailsDto) {
    state.clientDetails = payload
  },
  RESET_CLIENT_DETAILS(state) {
    state.clientDetails = undefined
  },
  TOGGLE_BLOCK_CLIENT(state) {
    if (!state.clientDetails) return
    state.clientDetails.blocked = !state.clientDetails.blocked
  },
  SET_AGENTS_SUGGESTIONS(state, payload: ClientDto[]) {
    state.agentsSuggestions = payload
  },
  SET_SELECTED_CLIENT_TYPE(state, payload: ClientRole) {
    state.selectedClientType = payload
  },
}
