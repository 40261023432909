import { EmployeeState } from '@bd/admin/types'
import { default as common } from '@bd/admin/config/common.json'

export const state: EmployeeState = {
  employees: {
    totalCount: 10,
    content: [],
  },
  filters: {
    pageSize: common.employeesPageSize,
  },
  employeeDetails: undefined,
}
