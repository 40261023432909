
import { defineComponent, PropType } from 'vue'
import Svg from '@bd/components/Svg/Svg.vue'
import { NavigationItem } from '@bd/admin/components/Navigation/types'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'NavigationItem',
  components: { Svg },
  props: {
    item: {
      type: Object as PropType<NavigationItem>,
      required: true,
    },
  },
  setup() {
    const router = useRouter()

    const onItemClick = (item: NavigationItem) => {
      if (!item.path) {
        return
      }
      router.push({ path: item.path })
    }

    return { onItemClick }
  },
})
