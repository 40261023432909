import { ActionTree } from 'vuex'
import { AppState, EmployeeParams, EmployeeState } from '@bd/admin/types'
import { logErr } from '@bd/helpers'
import { adminApi } from '@bd/api/admin-api'
import {
  AddEmployeeDto,
  EditEmployeeDto,
  EmployeeDetailsDto,
} from '@bd/api/admin-api/types'
import { addEmployee } from '@bd/api/admin-api/api'

export const actions: ActionTree<EmployeeState, AppState> = {
  async employeesList({ state, commit }) {
    commit('SET_LOADING', true, { root: true })
    try {
      const { data } = await adminApi.employees(state.filters)
      commit('SET_EMPLOYEES', data)
    } catch (error) {
      logErr(error)
    }
    commit('SET_LOADING', false, { root: true })
  },
  async setFilterAndRefresh({ commit, dispatch }, payload: EmployeeParams) {
    commit('SET_FILTERS', payload)
    await dispatch('employeesList')
  },

  async addEmployee(_, payload: AddEmployeeDto) {
    try {
      await addEmployee(payload)
    } catch (error) {
      logErr(error)
      throw error
    }
  },

  async employee({ commit }, id: EmployeeDetailsDto['userId']) {
    try {
      const { data } = await adminApi.employee(id)
      commit('SET_EMPLOYEE_DETAILS', data)
    } catch (error) {
      logErr(error)
    }
  },

  async updateEmployee(_, payload: EditEmployeeDto) {
    try {
      await adminApi.updateEmployee(payload)
    } catch (error) {
      logErr(error)
      throw error
    }
  },
  async removeEmployee({ commit }, id: EmployeeDetailsDto['userId']) {
    try {
      await adminApi.removeEmployee(id)
      commit('RESET_EMPLOYEE_DETAILS')
    } catch (error) {
      logErr(error)
      throw error
    }
  },

  async blockEmployee({ commit }, id: EmployeeDetailsDto['userId']) {
    try {
      await adminApi.blockEmployee(id)
      commit('SET_EMPLOYEE_BLOCKED', true)
    } catch (error) {
      logErr(error)
      throw error
    }
  },

  async unblockEmployee({ commit }, id: EmployeeDetailsDto['userId']) {
    try {
      await adminApi.unblockEmployee(id)
      commit('SET_EMPLOYEE_BLOCKED', false)
    } catch (error) {
      logErr(error)
      throw error
    }
  },
}
