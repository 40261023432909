import { createStore, Store, useStore } from 'vuex'

import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'

import { IS_DEV, vuexLogger } from '@bd/helpers'
import {
  auth,
  staticData,
  userProfile,
  calendar,
  calendarEvent,
} from '@bd/store-modules'
import { AppState } from '@bd/admin/types'

import offers from './modules/offers'
import agents from './modules/agents'
import employees from './modules/employees'
import clients from './modules/clients'

export type AppStore = Store<AppState>
export const useAppStore = (): AppStore => useStore<AppState>()

const store = createStore({
  strict: IS_DEV,
  state,
  mutations,
  actions,
  modules: {
    auth,
    staticData,
    offers,
    agents,
    employees,
    userProfile,
    clients,
    calendar,
    calendarEvent,
  },
  plugins: [vuexLogger('vuex.admin')],
})

if (IS_DEV) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(window as any).$store = store
}

export default store
