import { default as common } from '@bd/admin/config/common.json'
import { ClientState } from '@bd/admin/types'
import { UserRole } from '@bd/api'

export const state: ClientState = {
  clients: {
    hasNext: true,
    content: [],
  },
  filters: {
    sort: common.defaultClientsSort,
    userRole: UserRole.SELLER,
  },
  agentsSuggestions: [],
  selectedClientType: UserRole.SELLER,
}
