import { Module } from 'vuex'
import { AgentState, AppState } from '@bd/admin/types'
import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'

const agents: Module<AgentState, AppState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default agents
