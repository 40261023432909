
import { defineComponent } from 'vue'
import Navigation from '@bd/admin/components/Navigation/Navigation.vue'
import Toast from 'primevue/toast'
import { useAppStore } from '@bd/admin/store'
import { useToastConfig, OfflineScreen } from '@bd/components'
import { useOnline } from '@vueuse/core'

export default defineComponent({
  name: 'AppLayout',
  components: { Navigation, Toast, OfflineScreen },
  setup() {
    const store = useAppStore()
    const { toastZIndex } = useToastConfig()

    const online = useOnline()

    store.dispatch('calendar/setSelectedAgentId')

    return {
      toastZIndex,
      online,
    }
  },
})
