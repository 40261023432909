import { UserRole } from '@bd/api'

export const getClientTypeFromRoles = (roles: UserRole[]) => {
  if (roles.includes(UserRole.SELLER)) {
    return UserRole.SELLER
  }
  if (roles.includes(UserRole.CUSTOMER)) {
    return UserRole.CUSTOMER
  }
  return null
}
