<template>
  <div
    class="navigation-item d-flex align-items-center"
    :class="{ active: item.active }"
    @click="onItemClick(item)"
  >
    <Svg class="navigation-item-icon" :src="item.icon" />
    <div class="navigation-item-label pl-3 d-flex align-items-center">
      <span>{{ item.label }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import Svg from '@bd/components/Svg/Svg.vue'
import { NavigationItem } from '@bd/admin/components/Navigation/types'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'NavigationItem',
  components: { Svg },
  props: {
    item: {
      type: Object as PropType<NavigationItem>,
      required: true,
    },
  },
  setup() {
    const router = useRouter()

    const onItemClick = (item: NavigationItem) => {
      if (!item.path) {
        return
      }
      router.push({ path: item.path })
    }

    return { onItemClick }
  },
})
</script>

<style lang="scss" scoped>
.navigation-item {
  padding: 0.75rem 1.125rem;
  border-radius: 8px;
  margin-bottom: 6px;
  height: 55px;
  cursor: pointer;

  &.active,
  &:hover {
    background-color: $alabaster;

    .navigation-item-icon {
      color: $primary-color;
    }

    .navigation-item-label {
      font-weight: 600;
      color: $port-gore;
    }
  }
}

.navigation-item-icon {
  max-width: 20px;
  max-height: 20px;
  color: $blue-haze;
}

.navigation-item-label {
  color: $blue-haze;
}
</style>
