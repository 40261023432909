import { EmployeeParams } from '@bd/admin/types'
import { Filters, FiltersState } from '@bd/store-modules/types'
import { ClientParams } from '@bd/api'

export const addOrRemoveFilter = (filters: FiltersState, payload: Filters) => {
  const prop = Object.keys(payload)[0] as keyof Filters
  const value = Object.values(payload)[0] as string | number
  const rangeValues = [
    'priceFrom',
    'priceTo',
    'roomsFrom',
    'roomsTo',
    'houseAreaFrom',
    'houseAreaTo',
    'landAreaFrom',
    'landAreaTo',
    'floorFrom',
    'floorTo',
    'constructionYearFrom',
    'constructionYearTo',
  ] as const
  const singleValues = ['propertyType', 'agentId'] as const
  const multiValues = ['cityIds', 'districtIds'] as const

  // Clears the district filter when the city filter changes
  if (
    filters.cityIds &&
    payload.cityIds &&
    filters.cityIds !== payload.cityIds
  ) {
    filters.districtIds = []
  }

  const isOneOf = <T extends readonly string[]>(
    prop: string,
    opts: T,
  ): prop is T[number] => opts.includes(prop)

  if (isOneOf(prop, rangeValues)) {
    if (value === 0) {
      const { [prop]: value, ...rest } = filters
      return rest
    } else {
      const updatedFilter = { ...filters, [prop]: value }
      return updatedFilter
    }
  }
  if (isOneOf(prop, singleValues)) {
    if (filters[prop] === value) {
      const { [prop]: value, ...rest } = { ...filters, pageIndex: 0 }
      return rest
    } else {
      const updatedFilter = { ...filters, pageIndex: 0, [prop]: value }
      return updatedFilter
    }
  }
  // TODO use client/agent mutation service function instead function below
  if (isOneOf(prop, multiValues)) {
    if (!filters[prop]) {
      return { ...filters, ...{ [prop]: [value] } }
    } else if (filters[prop]?.includes(value as number)) {
      const updatedValues = {
        [prop]: filters[prop]?.filter((item: number) => item !== value),
      }
      return { ...filters, ...updatedValues }
    } else {
      const updatedValues = {
        [prop]: [...(filters[prop] || []), value],
      }
      return { ...filters, ...updatedValues }
    }
  }
  filters.pageIndex = value as number
  return filters
}

export const isResetOffers = (payload: Filters) => {
  const prop = Object.keys(payload)[0] as keyof Filters
  if (prop !== 'agentId') {
    return false
  }
  return true
}

export const addOrRemoveEmployeesFilter = (
  filters: EmployeeParams,
  payload: EmployeeParams,
) => {
  const prop = Object.keys(payload)[0] as keyof EmployeeParams
  const value = Object.values(payload)[0] as string | number
  const singleValues = ['role'] as const

  const isOneOf = <T extends readonly string[]>(
    prop: string,
    opts: T,
  ): prop is T[number] => opts.includes(prop)

  if (isOneOf(prop, singleValues)) {
    if (filters[prop] === value) {
      const { [prop]: value, ...rest } = { ...filters, pageIndex: 0 }
      return rest
    } else {
      const updatedFilter = { ...filters, pageIndex: 0, [prop]: value }
      return updatedFilter
    }
  }
  filters.pageIndex = value as number
  return filters
}

export const addOrRemoveClientsFilter = (
  filters: ClientParams,
  payload: ClientParams,
) => {
  const prop = Object.keys(payload)[0] as keyof ClientParams
  const value = Object.values(payload)[0] as string | number
  const singleValues = ['agentId', 'userRole'] as const
  const textValues = ['name'] as const

  const isOneOf = <T extends readonly string[]>(
    prop: string,
    opts: T,
  ): prop is T[number] => opts.includes(prop)

  if (isOneOf(prop, singleValues)) {
    if (filters[prop] === value) {
      const { [prop]: value, ...rest } = { ...filters, pageIndex: 0 }
      return rest
    } else {
      const updatedFilter = { ...filters, pageIndex: 0, [prop]: value }
      return updatedFilter
    }
  }

  if (isOneOf(prop, textValues)) {
    if (String(value).length) {
      const updatedFilter = { ...filters, pageIndex: 0, [prop]: value }
      return updatedFilter
    } else {
      const { [prop]: value, ...rest } = { ...filters, pageIndex: 0 }
      return rest
    }
  }
  filters.pageIndex = value as number
  return filters
}
