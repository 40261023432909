/* eslint-disable no-console */

import { trimSlash } from '@bd/helpers'
import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  const serviceWorkerPath = `${trimSlash(
    process.env.VUE_APP_ROOT_URL,
  )}/service-worker.js`
  console.log('registerSW:', serviceWorkerPath)

  register(serviceWorkerPath, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB',
      )
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(reg) {
      console.log('New content is available; please refresh.')
      document.dispatchEvent(
        new CustomEvent('swUpdated', {
          detail: reg,
        }),
      )
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.',
      )
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}
