<template>
  <main class="container-fluid">
    <div class="row align-items-center">
      <section class="app-layout-navigation d-none d-md-flex">
        <Navigation />
      </section>
      <section class="app-layout-content col p-0" v-if="online">
        <router-view />
      </section>
      <div class="offline-container" v-show="!online">
        <OfflineScreen />
      </div>
    </div>
  </main>
  <Toast :baseZIndex="toastZIndex" position="bottom-right" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Navigation from '@bd/admin/components/Navigation/Navigation.vue'
import Toast from 'primevue/toast'
import { useAppStore } from '@bd/admin/store'
import { useToastConfig, OfflineScreen } from '@bd/components'
import { useOnline } from '@vueuse/core'

export default defineComponent({
  name: 'AppLayout',
  components: { Navigation, Toast, OfflineScreen },
  setup() {
    const store = useAppStore()
    const { toastZIndex } = useToastConfig()

    const online = useOnline()

    store.dispatch('calendar/setSelectedAgentId')

    return {
      toastZIndex,
      online,
    }
  },
})
</script>

<style lang="scss" scoped>
.app-layout-navigation {
  height: 100vh;
  padding: 1.75rem;
  flex: 0 1 15%;
  max-width: 290px;
  min-width: 230px;
}

.app-layout-content {
  height: 100vh;
  background-color: $alabaster;
  overflow-y: hidden;
}
.offline-container {
  flex: 1;
}
</style>
