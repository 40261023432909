import { logErr } from '@bd/helpers'
import { ActionContext } from 'vuex'
import { AppState } from '@bd/admin/types'

type Ctx = ActionContext<unknown, AppState>

export const withErrorHandling = <Payload>(
  fn: (payload: Payload) => unknown,
) => {
  return async (_ctx: Ctx, payload: Payload) => {
    try {
      await fn(payload)
    } catch (error) {
      logErr(error)
      throw error
    }
  }
}
