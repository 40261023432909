import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import '@bd/assets/styles/reset.scss'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import '@bd/assets/styles/bootstrap-grid.css'
import '@bd/assets/styles/helpers.scss'
import '@bd/assets/styles/overrides.scss'
import '@bd/assets/styles/typography.scss'
import '@bd/assets/styles/immoby-theme.scss'
import '@bd/assets/styles/transitions.css'

import { i18n } from './plugins/vue-i18n-next-plugin'
import PrimeVue from 'primevue/config'
import Button from 'primevue/button'
import Tooltip from 'primevue/tooltip'

import { useUnauthorizedInterceptor } from '@itcraft-bestdeal/components'
import ToastService from 'primevue/toastservice'

useUnauthorizedInterceptor(store, router)

createApp(App)
  .use(PrimeVue)
  .use(ToastService)
  .use(store)
  .use(i18n)
  .use(router)
  .directive('tooltip', Tooltip)
  .component('Button', Button)
  .mount('#app')
