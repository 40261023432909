<template>
  <div
    class="navigation h-100 w-100 d-flex flex-column justify-content-between"
  >
    <div class="navigation-top">
      <div class="navigation-content text-center">
        <img :src="require('@bd/assets/images/immoby-horizontal-logo.svg')" />
      </div>
      <NavigationItem
        v-for="item of navigationItems"
        :key="item.path"
        :item="item"
      />
    </div>
    <div class="navigation-bottom">
      <Button
        class="p-button-sm p-button-secondary w-100"
        :label="t('logout')"
        @click="onLogoutClick"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import NavigationItem from './NavigationItem.vue'
import { useI18n } from 'vue-i18n'
import { buildNavigation } from '@bd/admin/config'
import { useRouter } from 'vue-router'
import { useAppStore } from '@bd/admin/store'

export default defineComponent({
  name: 'Navigation',
  components: { NavigationItem },
  setup() {
    const i18n = useI18n()
    const router = useRouter()
    const store = useAppStore()

    const navigationItems = computed(() =>
      buildNavigation(router, store).filter(
        (i) => i.visible === true || i.visible == undefined,
      ),
    )

    const onLogoutClick = async () => {
      await store.dispatch('auth/authLogout')
      router.push({ path: '/login' })
    }

    return {
      ...i18n,
      navigationItems,
      onLogoutClick,
    }
  },
})
</script>

<style lang="scss" scoped>
.navigation {
  padding-top: 1.375rem;
}

.navigation-content {
  margin-bottom: 5rem;
}
</style>
