
import { computed, defineComponent } from 'vue'
import NavigationItem from './NavigationItem.vue'
import { useI18n } from 'vue-i18n'
import { buildNavigation } from '@bd/admin/config'
import { useRouter } from 'vue-router'
import { useAppStore } from '@bd/admin/store'

export default defineComponent({
  name: 'Navigation',
  components: { NavigationItem },
  setup() {
    const i18n = useI18n()
    const router = useRouter()
    const store = useAppStore()

    const navigationItems = computed(() =>
      buildNavigation(router, store).filter(
        (i) => i.visible === true || i.visible == undefined,
      ),
    )

    const onLogoutClick = async () => {
      await store.dispatch('auth/authLogout')
      router.push({ path: '/login' })
    }

    return {
      ...i18n,
      navigationItems,
      onLogoutClick,
    }
  },
})
