import { AppState } from '@bd/admin/types'

export const state: AppState = {
  isLoading: false,
  dialog: {
    dashboardFilters: false,
    proposalModal: false,
    counterProposalModal: false,
  },
}
