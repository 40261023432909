import { addOrRemoveEmployeesFilter } from '@bd/admin/services/mutation.service'
import { Employees, EmployeeState } from '@bd/admin/types'
import { EmployeeDetailsDto, EmployeeParams } from '@bd/api/admin-api/types'
import { uniqBy } from '@bd/helpers'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<EmployeeState> = {
  SET_EMPLOYEES(state, payload: Employees) {
    if (!state.filters.pageIndex) {
      state.employees = payload
    } else {
      state.employees = {
        ...payload,
        content: uniqBy(
          [...state.employees.content, ...payload.content],
          (employee) => employee.userId,
        ),
      }
    }
  },
  SET_FILTERS(state, payload: EmployeeParams) {
    const filters = { ...state.filters }
    const result = addOrRemoveEmployeesFilter(filters, payload)
    if (result) {
      state.filters = result
    }
  },
  SET_EMPLOYEE_DETAILS(state, payload: EmployeeDetailsDto) {
    state.employeeDetails = payload
  },
  SET_EMPLOYEE_BLOCKED(state, payload: boolean) {
    if (state.employeeDetails) {
      state.employeeDetails.blocked = payload
    }
  },
}
