import { ActionTree } from 'vuex'
import { AppState, AdminOfferState } from '@bd/admin/types'
import { logErr } from '@bd/helpers'
import { adminApi } from '@bd/api/admin-api'
import { commonApi, PageableParams } from '@bd/api'
import { AddOfferStatisticsDto, EditOfferDto } from '@bd/api/admin-api/types'
import { Filters, FiltersState, OfferDetailsDto } from '@bd/store-modules/types'
import { withErrorHandling } from '../../helpers'
import {
  allCitiesLoaded,
  getNextPageIndex,
  isLoadingChunk,
} from './gettersHelper'

export const actions: ActionTree<AdminOfferState, AppState> = {
  saveAsDraft: withErrorHandling(async (payload: EditOfferDto) => {
    await adminApi.addOffer({ ...payload, publish: false })
  }),

  updateAndPublishOffer: withErrorHandling(async (payload: EditOfferDto) => {
    await adminApi.updateOffer({ ...payload, publish: true })
  }),

  saveAndPublishOffer: withErrorHandling(async (payload: EditOfferDto) => {
    await adminApi.addOffer({ ...payload, publish: true })
  }),

  publishOffer: withErrorHandling(async (id: OfferDetailsDto['id']) => {
    await adminApi.publishOffer(id)
  }),

  unpublishOffer: withErrorHandling(async (id: OfferDetailsDto['id']) => {
    await adminApi.unpublishOffer(id)
  }),

  updateOffer: withErrorHandling(async (payload: EditOfferDto) => {
    await adminApi.updateOffer(payload)
  }),

  removeOffer: withErrorHandling(async (id: OfferDetailsDto['id']) => {
    await adminApi.removeOffer(id)
  }),

  exportToAsari: withErrorHandling(async (id: OfferDetailsDto['id']) => {
    await adminApi.exportToAsari(id)
  }),

  addStatistics: withErrorHandling(async (payload: AddOfferStatisticsDto) => {
    await adminApi.addStatistics(payload)
  }),

  async getOfferDetails({ commit }, offerId: OfferDetailsDto['id']) {
    try {
      const { data } = await adminApi.getOfferDetails(offerId)
      commit('SET_OFFER_DETAILS', data)
    } catch (error) {
      logErr(error)
    }
  },

  async citiesAndProvincesList({ state, commit }, pageSize: number) {
    if (isLoadingChunk(state) || allCitiesLoaded(state)) {
      return
    }
    const params: PageableParams = {
      pageSize: pageSize,
      pageIndex: getNextPageIndex(state),
    }

    commit('SET_CITIES_AND_PROVINCES_LOADING', true)
    try {
      const { data } = await commonApi.citiesAndProvincesUsed(params)
      commit('ADD_CITIES_AND_PROVINCES_CHUNK', data)
    } finally {
      commit('SET_CITIES_AND_PROVINCES_LOADING', false)
    }
  },

  async cityDistricts({ commit }, payload: number) {
    try {
      const { data } = await commonApi.districts(payload)
      commit('SET_DISTRICTS', data)
    } catch (error) {
      logErr(error)
    }
  },

  async clearFilterAndRefresh(
    { commit },
    payload?: { keep: (keyof FiltersState)[] },
  ) {
    commit('CLEAR_FILTERS', payload?.keep)
    commit('APPLY_FILTERS')
  },

  async setFilters({ commit }, payload: Filters) {
    commit('SET_FILTERS', payload)
  },

  async setFilterAndRefresh({ commit }, payload: Filters) {
    commit('SET_FILTERS', payload)
    commit('APPLY_FILTERS')
  },
}
