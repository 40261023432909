import { AppStore } from '@bd/admin/store'
import { UserRole } from '@bd/api'
import { userHasRoles } from '@bd/store-modules/modules/auth/getters-helpers'
import { NavigationGuardNext, RouteLocationNormalizedLoaded } from 'vue-router'

const adminGuard = (
  store: AppStore,
  availableRoles: UserRole[] = [UserRole.ADMIN],
) => (
  _to: RouteLocationNormalizedLoaded,
  _from: RouteLocationNormalizedLoaded,
  next: NavigationGuardNext,
) => {
  if (userHasRoles(store.state, availableRoles)) {
    next()
  } else {
    next({ path: '/' })
  }
}

export default adminGuard
