<template>
  <div>
    <MobileAuthNavigation
      v-if="showAuthNavigation"
      :enabledRoutes="AUTH_NAVIGATION_ROUTES"
    />
    <div class="container-fluid px-0">
      <router-view />
    </div>
  </div>
  <ConfirmDialog
    v-model:visible="showPWAUpdateDialog"
    :title="t('version_update.title')"
    :description="t('version_update.description')"
    @confirm="onUpdateConfirm"
    @reject="showPWAUpdateDialog = false"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import {
  MobileAuthNavigation,
  shouldShowAuthNavigation,
  ConfirmDialog,
  usePWAUpdate,
} from '@bd/components'
import { useRoute } from 'vue-router'
import { useAppStore } from '@bd/admin/store'
import { AUTH_NAVIGATION_ROUTES } from '@bd/admin/config'
import { usePrimeVue } from 'primevue/config'
import { I18nValue, translatePrimeVue } from '@bd/components/i18n'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    MobileAuthNavigation,
    ConfirmDialog,
  },
  setup() {
    const i18n = useI18n()
    const messages = i18n.messages.value as I18nValue
    const route = useRoute()
    const store = useAppStore()
    const primeVue = usePrimeVue()

    translatePrimeVue(messages, i18n.locale.value, primeVue)

    const showAuthNavigation = shouldShowAuthNavigation(
      route,
      AUTH_NAVIGATION_ROUTES,
    )

    const fetchInitialData = async () => {
      await Promise.all([
        store.dispatch('userProfile/userProfile'),
        store.dispatch('staticData/loadData'),
      ])
    }
    onMounted(() => {
      fetchInitialData()
    })

    const showPWAUpdateDialog = ref(false)
    const { refreshApp } = usePWAUpdate(() => {
      showPWAUpdateDialog.value = true
    })

    const onUpdateConfirm = () => {
      refreshApp()
      showPWAUpdateDialog.value = false
    }

    return {
      t: i18n.t,
      showAuthNavigation,
      AUTH_NAVIGATION_ROUTES,
      showPWAUpdateDialog,
      onUpdateConfirm,
    }
  },
})
</script>
