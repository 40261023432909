import { Module } from 'vuex'
import { AdminOfferState, AppState } from '@bd/admin/types'

import { state } from './state'
import { actions } from './actions'
import { mutations } from './mutations'

const offers: Module<AdminOfferState, AppState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default offers
