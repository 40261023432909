import { default as common } from '@bd/admin/config/common.json'
import { AdminOfferState } from '@bd/admin/types'
import { emptyLoadableResource } from '@bd/helpers'

export const state: AdminOfferState = {
  offers: {
    pageNumber: 0,
    pageSize: 10,
    totalCount: 10,
    content: [],
  },
  citiesAndProvinces: emptyLoadableResource(),
  districts: [],
  filters: {
    pageSize: common.offersPerPage,
    showDrafts: common.showDraftsOffers,
  },
  searchedFilters: {
    pageSize: 20,
    showDrafts: true,
  },
}
